import { useStaticQuery, graphql } from "gatsby"
import { useRoutes } from "@app/hooks/useRoutes"
import { useCore } from "@app/hooks/useCore"

export type InstagramItem = {
  key: string
  title: string
  type: string
  link: string
  image_url: string
  media_url: string | null
  image: GatsbyTypes.SanityImageAsset
  products?: GatsbyTypes.SanityProduct[]
  timestamp: string
  published: boolean
}

export const useInstagram = () => {
  const {
    helpers: { edgeNormaliser },
  } = useCore()
  const { urlResolver } = useRoutes()

  const { images } = useStaticQuery<GatsbyTypes.StaticInstagramQuery>(graphql`
    query StaticInstagram {
      images: allSanityInstagram(filter: { published: { eq: true } }, limit: 4, sort: { fields: timestamp, order: DESC }) {
        edges {
          node {
            ...SanityInstagramFragment
          }
        }
      }
    }
    fragment SanityInstagramFragment on SanityInstagram {
      key: _id
      title
      text
      type
      link
      image_url
      media_url
      image {
        asset: _rawAsset(resolveReferences: { maxDepth: 2 })
      }
      products: _rawRelated(resolveReferences: { maxDepth: 2 })
      timestamp
      published
    }
  `)

  const items = edgeNormaliser(images).map((item: any) => ({
    ...item,
    image: {
      asset: item.image.asset,
    },
    products: item?.products?.map((product: any) => ({
      ...product,
      key: product?._id,
      url: urlResolver(product),
    })),
  })) as InstagramItem[]

  return {
    items,
  }
}
