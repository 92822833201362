import React from "react"
import { Container, Heading, Grid, Box, Text, Link } from "@chakra-ui/react"

import { AnalyticProps, withSection } from "@app/hoc/Section"
import { useInstagram } from "@app/hooks/useInstagram"
import { useRoutes } from "@app/hooks/useRoutes"
import ButtonWithIcon from "@app/components/Input/ButtonWithIcon"
import InstagramCard from "@app/components/Sections/Instagram/InstagramCard"

type Props = GatsbyTypes.SanitySectionInstagram & AnalyticProps

const Instagram: React.FC<Props> = ({ title, handle, link, innerRef, handleTrackingClick, tag }) => {
  const { urlResolver } = useRoutes()
  const { items } = useInstagram()
  const customLink = urlResolver(link)

  return items.length ? (
    <Box as="section" ref={innerRef}>
      <Container
        maxW="container.4xl"
        px={{ base: "4", md: "8", lg: "20" }}
        py={{ base: "8", lg: "18" }}
        bg="background.white"
        textAlign="center"
      >
        {title && (
          <Heading as={tag} size="h3">
            {title}
          </Heading>
        )}

        {handle && customLink && (
          <Text size="largeParagraph" mt={{ base: "4", lg: "3" }}>
            <Link href={customLink.url} onClick={handleTrackingClick}>
              {handle}
            </Link>
          </Text>
        )}

        <Grid
          templateColumns={{ base: "repeat(2, 1fr)", md: "repeat(4, 1fr)" }}
          columnGap={{ base: "4", lg: "8" }}
          rowGap={{ base: "5", lg: "8" }}
          mt={{ base: "5", lg: "8" }}
        >
          {items.map((item, index) => (
            <InstagramCard key={index} item={item} handleTrackingClick={handleTrackingClick} />
          ))}
        </Grid>

        {customLink && (
          <Box mt={{ base: "5", lg: "8" }}>
            <ButtonWithIcon
              as={Link}
              href={customLink.url}
              onClick={handleTrackingClick}
              variant="solidSecondary"
              iconName="arrows/chevron-right"
              w={{ base: "full", lg: "auto" }}
              minW={{ lg: "40" }}
              maxW={{ base: "container.sm", lg: "unset" }}
            >
              {customLink.title}
            </ButtonWithIcon>
          </Box>
        )}
      </Container>
    </Box>
  ) : null
}

export default React.memo(withSection(Instagram))
