import React from "react"
import { AspectRatio, GridItem, Image } from "@chakra-ui/react"

import { useImage } from "@app/hooks/useImage"
import Link from "@app/components/Link"
import { InstagramItem } from "@app/hooks/useInstagram"
import { HandleTrackingClickType } from "@app/hoc/Section"

type Props = {
  item: InstagramItem
  handleTrackingClick: HandleTrackingClickType
}

const InstagramCard: React.FC<Props> = ({ item, handleTrackingClick }) => {
  const { getGatsbyImage } = useImage()
  const customImage = getGatsbyImage(item.image, { width: 430 })

  return (
    customImage && (
      <GridItem borderRadius="xl" overflow="hidden">
        <Link to={item?.link} onClick={handleTrackingClick} external={true}>
          <AspectRatio ratio={1 / 1}>
            <Image {...customImage} />
          </AspectRatio>
        </Link>
      </GridItem>
    )
  )
}

export default React.memo(InstagramCard)
