import React from "react"
import { Box, Button, ButtonProps, LinkProps } from "@chakra-ui/react"
import { Icon } from "@app/components/Icon"

type ButtonWithIconProps = ButtonProps &
  LinkProps & {
    iconName: string
  }

const ButtonWithIcon: React.FC<ButtonWithIconProps> = ({ iconName, ...props }) => (
  <Button {...props} rightIcon={<Icon name={iconName} width="20" height="20" />}>
    <Box as="span" transform="translateY(1px)">
      {props.children}
    </Box>
  </Button>
)

export default React.memo(ButtonWithIcon)
